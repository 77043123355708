html,
body {
  overflow-x: hidden;
  font-family: 'Karla', sans-serif;
  height: 100%;
  position: relative;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

input {
  font-family: 'Karla', sans-serif;
}

.MuiInputBase-input {
  font-family: 'Karla', sans-serif;
}

/* .MuiGrid-grid-xs-12 {
  width: 100%;
} */

footer {
  text-align: center;
  /* padding: 2%; */
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-top: 2%;
  height: 50px;
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#root {
  padding-bottom: 50px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: block;
}

header {
  width: 100%;
  height: 50px;
  /* background-color:grey; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 0.5px solid lightgrey;
}

.homeLinkContainer {
  width: 50%;
  padding-left: 2%;
}

.headerLinksContainer {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  padding-right: 2%;
}

.headerLink {
  padding-left: 5%;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: black;
}

a:hover {
  color: #4caf50;
}

a:visited {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  /* header {
    flex-direction: column;
    justify-content: center;
    height: 100px;
    align-items: center;
  }
  .homeLinkContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .headerLinksContainer {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  a {
    text-decoration: underline;
  } */
  header {
    display: none;
  }
}
